import React from "react";

export const TrendingHeart = () => {
  return (
    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_b_6779_62181)">
        <rect width="42" height="42" rx="21" fill="#F4F4F4" fillOpacity="0.85" />
        <path
          d="M16.135 13.7239C13.4485 13.7239 11.2699 15.8801 11.2699 18.5403C11.2699 20.6878 12.1213 25.7844 20.5019 30.9366C20.652 31.0279 20.8244 31.0762 21.0001 31.0762C21.1758 31.0762 21.3481 31.0279 21.4983 30.9366C29.8789 25.7844 30.7303 20.6878 30.7303 18.5403C30.7303 15.8801 28.5517 13.7239 25.8652 13.7239C23.1787 13.7239 21.0001 16.6429 21.0001 16.6429C21.0001 16.6429 18.8215 13.7239 16.135 13.7239Z"
          fill="#FF3E3E"
          stroke="#FF3E3E"
          strokeWidth="1.40115"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_b_6779_62181"
          x="-7"
          y="-7"
          width="56"
          height="56"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="3.5" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_6779_62181" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_6779_62181" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};
